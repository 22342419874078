import FancyPaper from "../components/FancyPaper";

const DocumentationManagement = () => {
  return (
    <>
      <FancyPaper pagetitle="Documentación">Documentación</FancyPaper>
    </>
  );
};

export default DocumentationManagement;
