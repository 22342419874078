import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Paper,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { RadioGroupInput } from "../duediligence/components/Input";
import FancyPaper from "../../components/FancyPaper";
import { useForm, Controller } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useData } from "../../hooks/useData";
import { useApp } from "../../hooks/useApp";
import {
  getConstitutionTime,
  getRequestAgrupacion,
} from "../../services/agrupaciones/AgrupacionService";
import { getRequestBranch } from "../../services/branch/BranchService";
import ErrorAlert from "../../components/utils/ErrorAlert";
import { getSingleFactors } from "../../services/factors/FactorsService";
import FindDescription from "../../components/utils/risk_levels/FindDescription";
import { getSubElements } from "../../services/subelements/SubElementsService";
import { getRequestUserMatriz } from "../../services/users/UserMatrizService";
import styled from "@emotion/styled";
import {
  createProfileMatriz,
  updateProfileMatriz,
  getProfileMatriz,
} from "../../services/profile/ProfileService";
import { PatternFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputLabelToForm from "../../components/InputLabelToForm";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));
const Text = styled(DialogContentText)(({ theme }) => ({
  fontFamily: "D-dinExp",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "17px",
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    style: {
      padding: "10px 0",
      alignSelf: "flex-start",
    },
  };
}

const Step1Agrupacion = ({
  showInitModal,
  setShowInitModal,
  dataUserSearched,
  setDataUserSearched,
  dataUserSearchedShow,
  setDataUserSearchedShow,
  optionsTipoPersona,
  setOptionsTipoPersona,
  expanded,
  setExpanded,
  expendedTab1,
  setExpendedTab1,
  expendedTab2,
  setExpendedTab2,
  factorsAndOptions,
  setFactorsAndOptions,

  listShow,
  setListShow,
  matrixGroups,
  setMatrixGroups,
  branchData,
  setBranchData,
  optionsSelected,
  setOptionsSelected,
  optionsEjecutivo,
  setOptionsEjecutivo,
  dataConstitutionTime,
  setDataConstitutionTime,
  setValueStep,
  isPep,
  setIsPep,
  listSearch,
  comments,
  setComments,
  commentsFactor,
  setCommentsFactor,
  setListSearchJCEResult,
}: any) => {
  const { companyIndexDB, subCompanyData, handleFetchFactors, factors } =
    useData();
  const { setLoading, setErrorMsg, riskData, setSuccessMsg } = useApp();
  const [currentDateTime, setCurrentDateTime] = useState(
    getFormattedDateTime()
  );
  const [valueTab, setValueTab] = useState(0);
  const [isRD, setIsRD] = useState<boolean | null>(null);
  const [initUpload, setInitUpload] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  // Todos los watchs
  const watchPersona = watch("persona");
  const watchEjecutivo = watch("ejecutivo");
  const watchCode = watch("code");
  const watchSucursal = watch("sucursal");
  const watchIdClient = watch("idClient");
  const watchClientName = watch("clientName");
  const watchLastNameClient = watch("lastNameClient");
  const watchBirthDate = watch("birthDate");
  const watchConstitucion = watch("constitucion");
  const watchComments = watch("comments");
  const watcCustomPersonType = watch("personType");

  console.log("watchIdClient: ", watchIdClient);

  /**
   * Determines the risk caption based on a number and PEP status.
   *
   * @param {number} number - The number to evaluate against risk details.
   * @returns {string | null} - The risk caption if a matching range is found, otherwise null.
   */

  function getCaptionByNumber(number: number): string | null {
    // Check if risk data is available
    if (!riskData) return null;

    // Find the specific risk item of type "2"
    const risk = riskData.find((item: any) => item.type === "2");

    // Return early if risk data for type "2" is not found
    if (!risk) return null;

    // Determine if the user has a "SI" value in the isPep array
    const havePEPYes = isPep.some((item: any) => item.value === "SI");

    // Check if there is any high-risk factor in optionsSelected
    const haveHighRiskFactor = optionsSelected.some(
      (item: any) => item.highRisk === 1
    );

    // If user has PEP status "SI" or any high-risk factor, return "Alto" immediately
    if (havePEPYes || haveHighRiskFactor) return "Alto";

    // Iterate through risk details to find a matching range for the provided number
    for (const detail of risk.details) {
      if (number >= detail.from && number <= detail.to) {
        return detail.caption;
      }
    }

    // Return null if no matching range is found
    return null;
  }

  const handleOpenInitModal = () => {
    setShowInitModal(true);
  };

  const handleCloseInitModal = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "escapeKeyDown") {
      return;
    }
    setShowInitModal(false);
    setDataUserSearchedShow(null);
  };

  // Esta función maneja la búsqueda de usuarios.
  const handleSearchUser = async (data: any) => {
    console.log(data, "data");

    const { searchUser } = data;
    // Observa el valor del campo 'searchUser'.

    try {
      // Inicia la carga.
      setLoading && setLoading(true);
      // Realiza una solicitud para obtener el perfil de la matriz.
      const response = await getProfileMatriz(
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id,
        searchUser
      );

      // Si la respuesta no contiene contenido, muestra un mensaje de error.
      if (response.content.length === 0) {
        setErrorMsg && setErrorMsg("Usuario no encontrado");
        setLoading && setLoading(false);
        return;
      }

      // Establece los datos del usuario buscado.
      setDataUserSearched(
        response.content.find(
          (item: any) => item.document_number === searchUser
        )
      );
      setDataUserSearchedShow(
        response.content.find(
          (item: any) => item.document_number === searchUser
        )
      );

      // Finaliza la carga.
      setLoading && setLoading(false);
    } catch (error) {
      // En caso de error, muestra el error y finaliza la carga.
      console.log(error);
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    const loadFormData = async () => {
      await uploadDataInForm();
    };

    if (dataUserSearchedShow !== null && listSearch !== null) {
      loadFormData();
    }
  }, [dataUserSearchedShow]);

  const uploadDataInForm = async () => {
    setInitUpload(true);
    //console.log("Ingresa uploadDataInForm");
    if (dataUserSearched) {
      //console.log("Ingresa 2 uploadDataInForm");
      //console.log(dataUserSearched, "dataUserSearched");
      /* console.log(
        "optionsTipoPersona en uploadDataInForm: ",
        optionsTipoPersona
      ); */
      const [clientName, lastNameClient] = dataUserSearched.name.split(" ");
      setValue("ejecutivo", dataUserSearched?.executive);
      setValue("sucursal", dataUserSearched.sucursal);
      setValue("dateAndTime", dataUserSearched.dateAndTime);
      setValue("idClient", dataUserSearched.document_number);
      setValue(
        "clientName",
        dataUserSearched.type === "F" ? clientName : dataUserSearched.name
      );
      setValue("lastNameClient", lastNameClient);
      setValue("birthDate", dataUserSearched.dob.split("/")[2]);
      setValue("constitucion", dataUserSearched.constitution_time);

      setValue("comments", comments);
      // setValue
    }

    const mergeOptions = [
      ...optionsTipoPersona[0].options,
      ...optionsTipoPersona[1].options,
    ];

    //console.log("mergeOptions: ", mergeOptions);

    let descriptionTipoPersona = {
      id: 0,
      description: "",
    };

    for (const factor of dataUserSearched?.factores) {
      if (
        mergeOptions.find((item: any) => item.id === factor.factor_option_id)
      ) {
        descriptionTipoPersona = mergeOptions.find(
          (item: any) => item.id === factor.factor_option_id
        );
        break;
      }
    }
    console.log(
      "descriptionTipoPersona.description: ",
      descriptionTipoPersona.description
    );

    setValueTipoPersona(descriptionTipoPersona.description);
    setValue("persona", descriptionTipoPersona.description);
    setShowInitModal(false);
  };

  // Esta función convierte los datos en opciones seleccionadas
  const convertDataInOptionsSelected = (dataOptions: any) => {
    // Inicializamos dos arrays vacíos
    let optionsSelected: any = [];
    let optionsSelectedPeps: any = [];

    //console.log("dataUserSearched?.factores: ", dataUserSearched?.factores);
    // Recorremos los factores del usuario buscado
    for (const factor of dataUserSearched?.factores) {
      // Recorremos las opciones de datos
      //console.log("dataOptions: ", dataOptions);
      for (const option of dataOptions) {
        // Si el nombre de la opción es "Datos PEPS"
        if (option.name === "Datos PEPS") {
          // Buscamos la opción seleccionada
          const optionSelected = option.factors.find(
            (item: any) => item.id === factor.factor_id
          );
          // Si encontramos la opción seleccionada
          if (optionSelected) {
            // Verificamos si la opción seleccionada tiene sub elementos
            const haveSubElement = optionSelected.options.find(
              (item: any) => item.id === factor.factor_option_id
            ).subElements;

            // Si la opción seleccionada tiene sub elementos
            if (haveSubElement) {
              // Agregamos la opción seleccionada a las opciones seleccionadas
              optionsSelected.push({
                id: factor.factor_option_id,
                factor: optionSelected.options.find(
                  (item: any) => item.id === factor.factor_option_id
                ).description,
                percentage: optionSelected.percentage,
                contribution:
                  calculatePonderation(
                    optionSelected.options.find(
                      (item: any) => item.id === factor.factor_option_id
                    ).risk
                  ) *
                  (optionSelected.percentage / 100),
                highRisk: rangeToHighRisk(
                  optionSelected.options.find(
                    (item: any) => item.id === factor.factor_option_id
                  ).risk
                ),
                optionsSelected: haveSubElement.find(
                  (item: any) => item.id === factor.sub_element_id
                ),
                optionId: factor.factor_option_id,
              });

              // Agregamos la opción seleccionada a las opciones seleccionadas de PEPS
              optionsSelectedPeps.push({
                name: optionSelected.options.find(
                  (item: any) => item.id === factor.factor_option_id
                ).description,
                value: haveSubElement.find(
                  (item: any) => item.id === factor.sub_element_id
                ).name,
              });
            }
          }
        }

        // Si encontramos la opción seleccionada y el nombre de la opción no es "Datos PEPS"
        if (
          option.factors.find((item: any) => item.id === factor.factor_id) &&
          option.name !== "Datos PEPS"
        ) {
          // Buscamos la opción seleccionada
          const optionSelected = option.factors.find(
            (item: any) => item.id === factor.factor_id
          );
          // Verificamos si la opción seleccionada tiene sub elementos
          const haveSubElement = optionSelected.options.find(
            (item: any) => item.id === factor.factor_option_id
          ).subElements;

          // Si la opción seleccionada tiene sub elementos
          if (haveSubElement) {
            // Agregamos la opción seleccionada a las opciones seleccionadas
            optionsSelected.push({
              id: factor.factor_id,
              factor: optionSelected.name,
              percentage: optionSelected.percentage,
              contribution:
                calculatePonderation(
                  optionSelected.options.find(
                    (item: any) => item.id === factor.factor_option_id
                  ).risk
                ) *
                (optionSelected.percentage / 100),
              highRisk: rangeToHighRisk(
                optionSelected.options.find(
                  (item: any) => item.id === factor.factor_option_id
                ).risk
              ),
              optionsSelected: optionSelected.options.find(
                (item: any) => item.id === factor.factor_option_id
              ),
              haveSubElement: haveSubElement,
              isSubElement: true,
              optionId: factor.factor_option_id,
            });

            // Buscamos el sub elemento seleccionado
            const subElementSelected = haveSubElement.find(
              (item: any) => item.id === factor.sub_element_id
            );

            // Agregamos el sub elemento seleccionado a las opciones seleccionadas
            optionsSelected.push({
              id: factor.factor_option_id,
              factor: optionSelected.options.find(
                (item: any) => item.id === factor.factor_option_id
              ).description,
              percentage: 0,
              contribution: 0,
              highRisk: 0,
              optionsSelected: {
                ...subElementSelected,
                // impact: 0,
                // probability: 0,
                // risk: 0,
              },
              isSubElement: true,
              optionId: factor.factor_option_id,
            });
          } else {
            // Si la opción seleccionada no tiene sub elementos, la agregamos a las opciones seleccionadas
            optionsSelected.push({
              id: factor.factor_id,
              factor: optionSelected.name,
              percentage: optionSelected.percentage,
              contribution:
                calculatePonderation(
                  optionSelected.options.find(
                    (item: any) => item.id === factor.factor_option_id
                  ).risk
                ) *
                (optionSelected.percentage / 100),
              highRisk: rangeToHighRisk(
                optionSelected.options.find(
                  (item: any) => item.id === factor.factor_option_id
                ).risk
              ),
              optionsSelected: optionSelected.options.find(
                (item: any) => item.id === factor.factor_option_id
              ),
              haveSubElement: haveSubElement,
              isSubElement: false,
              optionId: factor.factor_option_id,
            });
          }
        }
      }
    }
    // console.log("optionsSelected: ", optionsSelected);
    //console.log("optionsSelectedPeps: ", optionsSelectedPeps);
    // Establecemos las opciones seleccionadas y las opciones seleccionadas de PEPS
    /* for (let index = 0; index < optionsSelectedPeps.length; index++) {
      const element = optionsSelectedPeps[index];
      onChangeOptionsPEPS(
        optionsSelectedPeps[index].value,
        optionsSelectedPeps[index].name
      );
    } */

    setOptionsSelected(optionsSelected);
    setInitUpload(false);
    setIsPep(optionsSelectedPeps);
    setIsRD(true);
  };

  function filterByMatchingId(array1: any, array2: any) {
    // Convertir el array2 en un conjunto (Set) para búsquedas más eficientes
    const idSet = new Set(array2.map((item: any) => item.id));

    // Filtrar los elementos de array1 donde el id existe en el conjunto
    return array1.filter((item: any) => idSet.has(item.id));
  }

  const filterFactors = async () => {
    let filterFactor = null;
    switch (watchPersona) {
      case "Persona física nacional":
        filterFactor = factors.dataPhysical.content.filter(
          (f: any) => f.name === "Comentarios"
        );
        break;
      case "Persona Jurídica nacional":
        filterFactor = factors.dataLegal.content.filter(
          (f: any) => f.name === "Comentarios"
        );
        break;
      case "Persona Jurídica extranjera":
        filterFactor = factors.dataLegalGlobal.content.filter(
          (f: any) => f.name === "Comentarios"
        );
        break;
      default:
        filterFactor = factors.dataPhysicalGlobal.content.filter(
          (f: any) => f.name === "Comentarios"
        );
        break;
    }

    //console.log("filterFactor: ", filterFactor);

    let requestOptionCommentsFactor: any = await factorOptions(
      filterFactor[0].id
    );
    //console.log("requestOptionCommentsFactor: ", requestOptionCommentsFactor);

    let formatCommentsFactor = {
      factor_id: filterFactor[0].id,
      factor_option_id: requestOptionCommentsFactor?.content[0]?.id,
      sub_element_id: 0,
      text_response: "string",
    };

    setCommentsFactor(formatCommentsFactor);
  };

  const onSave = async (data: any) => {
    let request: any = null;
    const pesoTotal =
      optionsSelected.length > 0
        ? optionsSelected.reduce(
            (acc: any, curr: any) => acc + curr.percentage,
            0
          )
        : 0;

    if (pesoTotal !== 100) {
      setErrorMsg &&
        setErrorMsg(
          "El peso total debe ser 100%, por favor elija todas las opciones"
        );
      return;
    }

    setLoading && setLoading(true);

    //Pienso hacer una uunión de esta data, para indicar que valores  de optionsSelected es PEPS

    const filterPeps = factorsAndOptions.filter(
      (item: any) => item.name === "Datos PEPS"
    )[0].factors[0]?.options;

    if (dataUserSearched?.id) {
      //console.log("filterPeps: ", filterPeps);
      //console.log("optionsSelected: ", optionsSelected);
      //1ero busco las opciones que pertenezcan a PEP en optionsSelected
      const filteredArray = await filterByMatchingId(
        optionsSelected,
        filterPeps
      );
      //console.log("filteredArray: ", filteredArray);

      //2do Recorro el filteredArray y le agrego el factorId en optionsSelected
      await dataUserSearched.factores.forEach((element1: any) => {
        // Find the corresponding element in array2 using .some()
        const foundElement = filteredArray.some((element2: any) => {
          if (element1.factor_option_id === element2.optionId) {
            // Add the "factorId" property to the found element
            element2.factorId = element1.factor_id;
            element2.isPep = true;
            return true; // Stop the iteration since a match was found
          }
          return false;
        });

        // If no match was found, log a message (optional)
        if (!foundElement) {
          console.log(
            `No corresponding element found for factor_option_id: ${element1.factor_option_id}`
          );
        }
      });
    }
    //console.log("optionsSelected: ", optionsSelected);

    /* let format_text_response =
      isPep.some((item: any) => item.value === "SI") &&
      `Es una persona políticamente expuesta (${
        isPep.find((item: any) => item.name.includes("anteriores"))?.value ===
        "SI"
          ? "Vínculo familiar"
          : "Directo"
      }) - `;
    optionsSelected.length > 0
      ? optionsSelected
          .filter((item: any) => item.highRisk === 1)
          .map(
            (item: any, index: number) =>
              `${item.factor} - ${
                item.optionsSelected.description ?? item.optionsSelected.name
              } ${optionsSelected.length - 1 !== index ? "," : ""} `
          )
      : ""; */

    let OldCommentsFactor = commentsFactor;
    OldCommentsFactor.text_response = watchComments;
    //OldCommentsFactor.text_response = format_text_response;
    setCommentsFactor(OldCommentsFactor);
    setComments(watchComments);

    const factores = optionsSelected
      .filter((itemFilter: any) => !itemFilter.isSubElement)
      .map((item: any) => {
        //console.log("factor item: ", item);
        const haveSubElement = item.haveSubElement;
        const isPep = item.isPep;
        //console.log("isPep: ", isPep);

        if (isPep) {
          return {
            factor_id: item.factorId,
            factor_option_id: item.optionId,
            sub_element_id: item.optionsSelected.id,
            text_response: "",
          };
        } else {
          return {
            factor_id: item.id,
            factor_option_id: item.optionsSelected.id,
            sub_element_id: haveSubElement
              ? optionsSelected.find(
                  (itemFilter: any) =>
                    itemFilter.optionId === item.optionsSelected.id
                ).optionsSelected.id
              : null,
          };
        }
      });
    factores.push(OldCommentsFactor);
    //console.log("ana: ", factores);

    const dataToSend = {
      company: companyIndexDB?.company?.id,
      sub_company: companyIndexDB?.subCompany?.id,
      name: watchPersona.includes("dica")
        ? watchClientName
        : `${watchClientName} ${watchLastNameClient}`,
      executive: watchEjecutivo,
      sucursal: watchSucursal,
      document_number: watchIdClient,
      createdBy: optionsEjecutivo.find(
        (item: any) => item.id === watchEjecutivo
      )?.username,
      code: watchCode,
      type: watchPersona.includes("dica") ? "J" : "F",
      factores: factores,
      final_result:
        optionsSelected.length > 0
          ? optionsSelected
              .reduce((acc: any, curr: any) => acc + curr.contribution, 0)
              .toFixed(2)
          : 0,
      high_risk:
        getCaptionByNumber(
          optionsSelected.length > 0
            ? optionsSelected
                .reduce((acc: any, curr: any) => acc + curr.contribution, 0)
                .toFixed(2)
            : 0
        ) === "Alto"
          ? true
          : false,
      dob: `01/01/${watchBirthDate}`,
      constitution_time: watchConstitucion ? watchConstitucion : null,
      risk_level: getCaptionByNumber(
        optionsSelected.length > 0
          ? optionsSelected
              .reduce((acc: any, curr: any) => acc + curr.contribution, 0)
              .toFixed(2)
          : 0
      ),
    };
    //console.log("dataToSend: ", dataToSend);
    const dataToUpdateSend = {
      id: dataUserSearched?.id ?? 0,
      company: companyIndexDB?.company?.id,
      sub_company: companyIndexDB?.subCompany?.id,
      name: watchPersona.includes("dica")
        ? watchClientName
        : `${watchClientName} ${watchLastNameClient}`,
      executive: watchEjecutivo,
      sucursal: watchSucursal,
      document_number: watchIdClient,
      user: optionsEjecutivo.find((item: any) => item.id === watchEjecutivo)
        ?.username,
      code: watchCode,
      type: watchPersona.includes("dica") ? "J" : "F",
      factores: factores,
      final_result:
        optionsSelected.length > 0
          ? optionsSelected
              .reduce((acc: any, curr: any) => acc + curr.contribution, 0)
              .toFixed(2)
          : 0,
      high_risk:
        getCaptionByNumber(
          optionsSelected.length > 0
            ? optionsSelected
                .reduce((acc: any, curr: any) => acc + curr.contribution, 0)
                .toFixed(2)
            : 0
        ) === "Alto"
          ? true
          : false,
      dob: `01/01/${watchBirthDate}`,
      constitution_time: watchConstitucion ? watchConstitucion : null,
      risk_level: getCaptionByNumber(
        optionsSelected.length > 0
          ? optionsSelected
              .reduce((acc: any, curr: any) => acc + curr.contribution, 0)
              .toFixed(2)
          : 0
      ),
    };
    //console.log("dataToUpdateSend: ", dataToUpdateSend);

    try {
      if (dataUserSearched?.id) {
        console.log("ingresa en actualizar");
        //request = true;
        console.log("dataToUpdateSend: ", dataToUpdateSend);
        request = await updateProfileMatriz(dataToUpdateSend);
        setDataUserSearched(request);
        setDataUserSearchedShow(request);
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg("Perfil de matriz de riesgo actualizado correctamente");
      } else {
        request = await createProfileMatriz(dataToSend);
        //const formatData = request;
        //formatData.factores = factores;
        setDataUserSearched(request);
        setDataUserSearchedShow(request);
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg("Perfil de matriz de riesgo guardado correctamente");
      }
    } catch (error) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg("Error al guardar el perfil de matriz de riesgo");
      console.log(error);
    }
    request !== null && setValueStep((prevStep: number) => prevStep + 1);
  };

  const calculatePonderation = (riesgoInherente: number) => {
    const factor: number =
      riskData?.find((item: any) => item.type === "1")?.factor || 0;
    const ponderation = riesgoInherente * factor;
    return ponderation;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!companyIndexDB?.company?.id) return;

      const subCompanyId =
        subCompanyData !== null ? companyIndexDB?.subCompany?.id : "";
      /*  handleFetchFactors &&
        handleFetchFactors(0, companyIndexDB.company.id, subCompanyId, 100); */

      const dataEjectutivo = await getRequestUserMatriz(
        companyIndexDB.company.id,
        subCompanyId,
        "Ejecutivo de ventas"
      );
      setOptionsEjecutivo(dataEjectutivo.content);

      const constitutionTime = await getConstitutionTime();
      //console.log(constitutionTime, "constitutionTime");
      if (subCompanyData !== null) setDataConstitutionTime(constitutionTime);
    };

    fetchData();
  }, [companyIndexDB, subCompanyData]);

  useEffect(() => {
    // console.log("factors:", !!factors);
    // console.log("factors.dataLegal:", !!factors.dataLegal);
    // console.log("dataUserSearched: ", dataUserSearched);
    // console.log("dataUserSearchedShow: ", dataUserSearchedShow);
    if (!!factors && !!factors.dataLegal && dataUserSearchedShow === null) {
      setShowInitModal(true);
    }

    const getDataFactor = async () => {
      await getTipoPerson();
    };

    if (matrixGroups !== null && factors !== null) {
      getDataFactor();
    }

    // @ts-ignore
  }, [factors, matrixGroups]);

  useEffect(() => {
    const getDataFactor = async () => {
      await cleanDataFactor();
      await filterFactors();
    };

    if (!!watchPersona) {
      setIsPep([]);
      getDataFactor();
    }
    // @ts-ignore
  }, [watchPersona]);

  const cleanDataFactor = async () => {
    setLoading && setLoading(true);
    setExpanded(false);
    // setOptionsSelected()

    try {
      const dataJ = factors?.dataLegal?.content || [];
      const dataF = factors?.dataPhysical?.content || [];
      const dataMerge = watchPersona.includes("ur") ? [...dataJ] : [...dataF];

      const factorsWithOptions = [];
      const dataMergeWithOptions: any = [];

      if (matrixGroups === null) return;

      // Usar Promise.all para manejar múltiples llamadas asíncronas
      await Promise.all(
        matrixGroups.map(async (matrixGroup: any) => {
          const factorsForGroup = dataMerge.filter(
            (factor) => factor.matrix_group === matrixGroup.id
          );

          const factorsWithOptionsForGroup = await Promise.all(
            factorsForGroup.map(async (factor) => {
              const options: any = await factorOptions(factor.id);
              const optionsWithSubElements = await Promise.all(
                options!!.content!!.map(async (option: any) => {
                  // console.log('option', option);
                  let subElementos = null;

                  if (option.has_sub_elements) {
                    // console.log('option', option);
                    const subElements: any = await getSubElements(0, option.id);
                    subElementos = subElements.content.map(
                      (subElement: any) => {
                        return {
                          id: subElement.id,
                          name: subElement.name,
                        };
                      }
                    );
                  }

                  return {
                    id: option.id,
                    description: option.name,
                    impact: option.impact,
                    risk: option.risk,
                    probability: option.probability,
                    subElements: subElementos,
                  };
                })
              );

              return {
                ...factor,
                options: optionsWithSubElements,
              };
            })
          );

          factorsWithOptions.push(...factorsWithOptionsForGroup);

          dataMergeWithOptions.push({
            ...matrixGroup,
            factors: factorsWithOptionsForGroup.filter(
              (factor) => factor.matrix_group === matrixGroup.id
            ),
          });

          setExpanded(true);
          setExpendedTab1(true);
        })
      );
      setFactorsAndOptions([...dataMergeWithOptions]);
      if (initUpload) {
        convertDataInOptionsSelected(dataMergeWithOptions);
      }

      setLoading && setLoading(false);
    } catch (error) {
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const getTipoPerson = async () => {
    try {
      //console.log("Ingresa en getTipoPerson");
      const dataF = factors?.dataLegal?.content || [];
      const dataJ = factors?.dataPhysical?.content || [];
      const dataMerge = [...dataF, ...dataJ];

      const tipoPersonaOptions = [];
      // setOptionsTipoPersona

      if (matrixGroups === null) return;

      for (const matrixGroup of matrixGroups) {
        //console.log("Ingresa a for de matrixGroup: ", matrixGroups);
        const factorsForGroup = dataMerge.filter(
          (factor) => factor.matrix_group === matrixGroup.id
        );

        for (const factor of factorsForGroup) {
          if (factor.name === "Tipo de persona") {
            const options: any = await factorOptions(factor.id);
            //console.log(options);
            tipoPersonaOptions.push({
              ...factor,
              options: options.content.map((option: any) => {
                return {
                  id: option.id,
                  description: option.name,
                  impact: option.impact,
                  risk: option.risk,
                  probability: option.probability,
                };
              }),
            });
          }
        }
      }
      //console.log("tipoPersonaOptions", tipoPersonaOptions);
      setOptionsTipoPersona(tipoPersonaOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const searchFactor = (label: string) => {
    //console.log("factorsAndOptions: ", factorsAndOptions);
    const factor = factorsAndOptions?.find(
      (factor: any) => factor.name === label
    );
    return factor ? factor.factors : [];
  };

  const factorOptions = async (id: string) => {
    try {
      const opciones = await getSingleFactors(id, 0, "", 1000);
      return opciones;
    } catch (error) {
      console.error("Error en factorOptions:", error);
    }
  };

  useEffect(() => {
    const dataInit = async () => {
      //if (companyIndexDB.company === null) bActivo = false;
      if (companyIndexDB.company !== null || companyIndexDB.company !== null) {
        if (companyIndexDB.company.length != 0) {
          await handleFetchAgrupacionData(0, "");
        }
      }
    };
    if (companyIndexDB !== null) {
      dataInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIndexDB]);

  const handleFetchAgrupacionData = async (
    currentPage: number,
    filter?: string
  ) => {
    // setLoading && setLoading(true);
    try {
      const companyId = companyIndexDB.company.id;
      const subcompanyId = companyIndexDB.subCompany
        ? companyIndexDB.subCompany.id
        : 0;
      let data: any = await getRequestAgrupacion(
        currentPage,
        filter,
        companyId,
        subcompanyId
      );
      //console.log("handleFetchAgrupacionData MatrixGroups: ", data);
      // Esta son las sucursales
      //No se tocan, no son necesarias a la logica
      let dataBranch: any = await getRequestBranch(
        currentPage,
        filter,
        companyId,
        subcompanyId
      );
      //console.log("handleFetchAgrupacionData dataBranch: ", dataBranch);

      if (dataBranch) {
        setBranchData(dataBranch);
      }

      //
      if (data) {
        setMatrixGroups(data.content);
        const listWithAgrupation = data.content.map((item: any) => {
          return {
            label: item.name,
          };
        });
        setListShow([...listWithAgrupation]);
      }

      // setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(!expanded);
    };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  function getFormattedDateTime() {
    return new Date().toLocaleString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      second: "numeric",
    });
  }

  //habilitar el reloj
  // useEffect(() => {
  //   // Actualizar la hora cada segundo
  //   const intervalId = setInterval(() => {
  //     setCurrentDateTime(getFormattedDateTime());
  //   }, 1000);

  //   // Limpia el intervalo cuando el componente se desmonta
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const setValueTipoPersona = async (value: any) => {
    try {
      // setOptionsSelected((prevOptionsSelected) => []);
      const mergeOptions = [
        ...optionsTipoPersona[0].options,
        ...optionsTipoPersona[1].options,
      ];
      const optionsSelectedFiltered = mergeOptions.find(
        (option: any) => option.description === value
      );
      //console.log("setValueTipoPersona: ", value);
      //console.log("optionsTipoPersona: ", optionsTipoPersona);

      const searchDataTipoPerson = optionsTipoPersona.find((option: any) => {
        return option.options.find(
          (option: any) => option.description === value
        );
      });

      //console.log("setValueTipoPersona: ", searchDataTipoPerson);

      // Limpiar optionsSelected
      setOptionsSelected([]);
      setIsRD(null);

      // Agregar el nuevo elemento
      setOptionsSelected([
        {
          id: searchDataTipoPerson.id,
          factor: "Tipo de persona",
          percentage: searchDataTipoPerson.percentage,
          contribution:
            calculatePonderation(optionsSelectedFiltered.risk) *
            (searchDataTipoPerson.percentage / 100),
          highRisk: rangeToHighRisk(optionsSelectedFiltered.risk),
          optionsSelected: optionsSelectedFiltered,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const rangeToHighRisk = (percentage: number) => {
    if (riskData === null) return 0;

    const range =
      riskData.find((item: any) => item.type === "1")?.details || [];
    const highRisk = range.find((item: any) => item.caption === "Alto");

    if (highRisk && percentage >= highRisk.from) {
      return 1;
    }

    return 0;
  };

  const onChangeOptionsSelected = (value: any, factor: any) => {
    if (value === null) {
      const optionsSelectedFiltered = optionsSelected.filter(
        (option: any) => option.factor !== factor.name
      );
      setOptionsSelected([...optionsSelectedFiltered]);
      // factor.name ===setIsRD(null);
      if (factor.name === "Residencia" || factor.name === "Pais Operaciones")
        setIsRD(null);
      return;
    }

    const contribution =
      calculatePonderation(value.risk) * (factor.percentage / 100);

    if (optionsSelected.find((option: any) => option.factor === factor.name)) {
      const optionsSelectedFiltered = optionsSelected.filter(
        (option: any) =>
          option.factor !== factor.name && option.factor !== "Provincia"
      );

      if (factor.name === "Residencia" || factor.name === "Pais Operaciones") {
        setOptionsSelected([
          ...optionsSelected.filter(
            (option: any) => option.factor !== "Provincia"
          ),
        ]);
      }
      setOptionsSelected([
        ...optionsSelectedFiltered,
        {
          id: factor.id,
          factor: factor.name,
          percentage: factor.percentage,
          contribution: contribution,
          highRisk: rangeToHighRisk(
            factor.options.find((option: any) => option.id === value.id).risk
          ),
          optionsSelected: factor.options.find(
            (option: any) => option.id === value.id
          ),
          haveSubElement: !!factor.options.find(
            (option: any) => option.id === value.id
          ).subElements,
        },
      ]);
    } else {
      setOptionsSelected([
        ...optionsSelected,
        {
          id: factor.id,
          factor: factor.name,
          percentage: factor.percentage,
          contribution: contribution,
          highRisk: rangeToHighRisk(
            factor.options.find((option: any) => option.id === value.id).risk
          ),
          optionsSelected: factor.options.find(
            (option: any) => option.id === value.id
          ),
          haveSubElement: !!factor.options.find(
            (option: any) => option.id === value.id
          ).subElements,
        },
      ]);
    }

    if (factor.name === "Residencia" || factor.name === "Pais Operaciones") {
      if (value.description === "República Dominicana") {
        setIsRD(true);
      } else {
        setIsRD(false);
      }
    }
  };

  const onChangeSubElementSelected = (value: any, factor: any) => {
    if (
      optionsSelected.find(
        (option: any) => option.factor === factor.description
      )
    ) {
      const optionsSelectedFiltered = optionsSelected.filter(
        (option: any) => option.factor !== factor.description
      );

      setOptionsSelected([
        ...optionsSelectedFiltered,
        {
          id: factor.id,
          factor: factor.description,
          percentage: 0,
          contribution: 0,
          highRisk: 0,
          optionsSelected: {
            name: value,
            id: factor.subElements.find((option: any) => option.name === value)
              .id,
          },
          isSubElement: true,
          optionId: factor.id,
        },
      ]);
    } else {
      setOptionsSelected([
        ...optionsSelected,
        {
          id: factor.id,
          factor: factor.description,
          percentage: 0,
          contribution: 0,
          highRisk: 0,
          optionsSelected: {
            name: value,
            id: factor.subElements.find((option: any) => option.name === value)
              .id,
          },
          isSubElement: true,
          optionId: factor.id,
        },
      ]);
    }
  };

  const onChangeOptionsPEPS = (value: any, factor: any) => {
    const searchIsPep = isPep.find(
      (item: any) => item.name === factor.description
    );

    console.log("searchIsPep: ", searchIsPep);

    if (searchIsPep) {
      const isPepUpdated = isPep.map((item: any) =>
        item.name === factor.description
          ? { name: item.name, value: value }
          : item
      );
      console.log("isPepUpdated: ", isPepUpdated);
      setIsPep([...isPepUpdated]);
    } else {
      setIsPep([...isPep, { name: factor.description, value: value }]);
    }
    const idPep = searchFactor("Datos PEPS")[0].id;

    const subOptionChoose = factor.subElements.find(
      (option: any) => option.name === value
    );

    if (
      optionsSelected.find(
        (option: any) => option.factor === factor.description
      )
    ) {
      const optionsSelectedFiltered = optionsSelected.filter(
        (option: any) => option.factor !== factor.description
      );

      setOptionsSelected([
        ...optionsSelectedFiltered,
        {
          factorId: idPep,
          optionId: factor.id,
          factor: factor.description,
          percentage: 0,
          contribution: 0,
          optionsSelected: subOptionChoose,
          highRisk: 0,
          highRiskPep: subOptionChoose.name === "SI" ? 1 : 0,
          isPep: true,
        },
      ]);
    } else {
      setOptionsSelected([
        ...optionsSelected,
        {
          factorId: idPep,
          optionId: factor.id,
          factor: factor.description,
          percentage: 0,
          contribution: 0,
          optionsSelected: subOptionChoose,
          highRisk: 0,
          highRiskPep: subOptionChoose.name === "SI" ? 1 : 0,
          isPep: true,
        },
      ]);
    }
  };

  const onChangeOptionsPEPSToNo = () => {
    const searchAllPeps = searchFactor("Datos PEPS")[0];

    const idPep = searchFactor("Datos PEPS")[0].id;

    const searchAllNoInPeps = searchAllPeps.options.map((factor: any) => {
      return factor;
    });

    const updatedOptionsSelected = searchAllNoInPeps
      .filter(
        (factor: any) =>
          factor.description !== "Tipo PEP" &&
          factor.description !== "Vinculación"
      )
      .map((factor: any) => ({
        factorId: idPep,
        optionId: factor.id,
        factor: factor.description,
        percentage: 0,
        contribution: 0,
        optionsSelected: factor.subElements.find(
          (option: any) => option.name === "NO"
        ),
        highRisk: 0,
        highRiskPep: 0,
        isPep: true,
      }));

    const filteredOptionsSelected = optionsSelected.filter(
      (option: any) =>
        !searchAllNoInPeps.some(
          (factor: any) => factor.description === option.factor
        )
    );

    const newOptionsSelected = [
      ...filteredOptionsSelected,
      ...updatedOptionsSelected,
    ];

    setOptionsSelected(newOptionsSelected);

    setIsPep([]);
  };

  const cleanAllData = () => {
    const searchTipoPersona = optionsSelected.filter(
      (item: any) => item.factor === "Tipo de persona"
    );

    //setOptionsSelected(searchTipoPersona);
    setIsPep([]);
    setIsRD(null);
    //setOptionsSelected([]);
    //setFactorsAndOptions([]);
    //setListShow([]);
    //setMatrixGroups(null);
    //setBranchData(null);
    setExpanded(false);
    setExpendedTab1(true);
    setValueTab(0);
    setValue("idClient", "");
    setValue("IDExpirationDate", "");
    setValue("clientName", "");
    setValue("lastNameClient", "");
    setValue("birthDate", "");
    setValue("constitucion", "");
    setValue("comments", "");
    setDataUserSearched(null);
    setDataUserSearchedShow(null);
    setListSearchJCEResult(null);
  };

  return (
    <>
      {!showInitModal && (
        <Grid container>
          <Box
            display="flex"
            justifyContent="end"
            width="100%"
            gap={3}
            marginBottom="2rem"
          >
            {/* <Button variant="contained" onClick={handleOpenInitModal}>
                  <Typography>Buscar usuario</Typography>
                </Button> */}

            <Box gap={3} display="flex">
              <Button variant="contained" onClick={cleanAllData}>
                <Typography>Limpiar</Typography>
              </Button>
            </Box>
          </Box>
          <Accordion
            expanded={expendedTab1}
            elevation={4}
            sx={{
              background: "#fafafa",
              width: "100%",
              boxShadow:
                "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
              borderRadius: "15px !important",
              "& .MuiAccordionDetails-root": {
                padding: 5,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              onClick={() => setExpendedTab1(!expendedTab1)}
              sx={{
                borderBottom: "1px solid #8BC8BC",
                borderRadius: 0,
                width: "100%",
                padding: "0 20px",
              }}
              id="panel1bh-header"
            >
              <Typography variant="h5" sx={{ width: "70%", flexShrink: 0 }}>
                Información del usuario
              </Typography>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Tipo de persona
                </Typography>
                <Controller
                  control={control}
                  name="persona"
                  render={({ field: { onChange, value } }) => (
                    <FormControl variant="standard" fullWidth>
                      <Select
                        {...register("persona")}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setOptionsSelected([]);
                          setValueTipoPersona(e.target.value);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {optionsTipoPersona.map((item: any) =>
                          item.options.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.description}>
                              {option.description}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Datos del ejecutivo
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={"code"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Código"
                        variant="standard"
                        defaultValue=""
                        value={
                          optionsEjecutivo
                            ? optionsEjecutivo.find(
                                (item: any) => item.id === watchEjecutivo
                              )?.identification
                            : ""
                        }
                        disabled
                        autoComplete="name3"
                        // shrink
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("code")}
                        error={errors.code && Boolean(errors.code)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="ejecutivo"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          marginTop: "4px",
                        }}
                      >
                        <InputLabel htmlFor="ejecutivo-select">
                          Ejecutivo
                        </InputLabel>
                        <Select value={value} onChange={onChange}>
                          {optionsEjecutivo &&
                            optionsEjecutivo.map((item: any, index: number) => (
                              <MenuItem value={item.id} key={index}>
                                {item.firstName} {item.lastName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="sucursal"
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          marginTop: "4px",
                        }}
                      >
                        <InputLabel htmlFor="sucursal-select">
                          Sucursal
                        </InputLabel>
                        <Select
                          value={value || ""}
                          // defaultValue={"FN"}
                          label="Sucursal"
                          onChange={onChange}
                          inputProps={{
                            name: "sucursal",
                            id: "sucursal-select",
                          }}
                        >
                          {branchData &&
                            branchData.content.map(
                              (item: any, index: number) => (
                                <MenuItem value={item.id} key={index}>
                                  {item.description}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={"dateAndTime"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Fecha y hora"
                        type="text"
                        variant="standard"
                        value={currentDateTime}
                        autoComplete="name3"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("dateAndTime")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Datos del cliente
                  </Typography>
                </Grid>
                {watchPersona ? (
                  <>
                    <Grid
                      item
                      xs={3}
                      sx={{ display: "flex", alignItems: "flex-end" }}
                    >
                      {watchPersona.includes("dica") ? (
                        <Controller
                          name={"idClient"}
                          control={control}
                          rules={{
                            required: "Este campo es obligatorio",
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Formato no válido",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              size="small"
                              label="No. ID"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={value}
                              autoComplete="name3"
                              sx={{ "& input": { pl: "0!important" } }}
                              {...register("idClient")}
                              error={
                                errors.idClient && Boolean(errors.idClient)
                              }
                            />
                          )}
                        />
                      ) : (
                        <>
                          <Controller
                            name={"idClient"}
                            control={control}
                            rules={{
                              required: "Este campo es obligatorio",
                              pattern: {
                                value: /^[0-9]+$/i,
                                message: "Formato no válido",
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <FormControl
                                sx={{ width: "100%" }}
                                className={`input-mapprisk ${
                                  errors.document_id_number && "error"
                                }`}
                              >
                                <FormLabel sx={{ color: "#5d5e60" }}>
                                  No. ID
                                </FormLabel>
                                <PatternFormat
                                  name={"idClient"}
                                  onPaste={(e: any) => e.preventDefault()}
                                  mask="_"
                                  format={"###-#######-#"}
                                  value={value}
                                  onChange={onChange}
                                  placeholder={"###-#######-#"}
                                />
                                <span></span>
                              </FormControl>
                            )}
                          />
                          <Button
                            variant="outlined"
                            size="medium"
                            sx={{ width: "140px" }}
                          >
                            Buscar ID
                          </Button>
                        </>
                      )}

                      {/*  {errors.idClient && Boolean(errors.idClient) && (
                        <FormHelperText>Campo es requerido</FormHelperText>
                      )} */}
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name={"IDExpirationDate"}
                        control={control}
                        defaultValue={new Date()}
                        render={({ field, ...props }) => (
                          <FormControl fullWidth sx={{ marginTop: "21px" }}>
                            <InputLabelToForm
                              error={errors.date && Boolean(errors.date)}
                            >
                              {watchPersona.includes("dica")
                                ? "Fecha Vencimiento Registro Mercantil"
                                : "Fecha vencimiento ID"}
                            </InputLabelToForm>
                            <DatePicker
                              locale="es"
                              selected={field.value}
                              minDate={new Date()}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              dateFormat={"dd/MM/yyyy"}
                            />
                          </FormControl>
                        )}
                        {...(errors.IDExpirationDate && (
                          <FormHelperText>Campo obligatorio</FormHelperText>
                        ))}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name={"clientName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label={
                              watchPersona.includes("dica")
                                ? "Nombre de la empresa"
                                : "Nombre del cliente"
                            }
                            // type='number'
                            variant="standard"
                            value={value}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="name3"
                            sx={{ "& input": { pl: "0!important" } }}
                            {...register("clientName")}
                            error={
                              errors.clientName && Boolean(errors.clientName)
                            }
                          />
                        )}
                      />
                    </Grid>
                    {watchPersona.includes("dica") && (
                      <Grid item xs={3}>
                        <Controller
                          control={control}
                          name="constitucion"
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <FormControl
                              variant="standard"
                              fullWidth
                              sx={{
                                marginTop: "4px",
                                color: dataUserSearchedShow ? "black" : "gray",
                              }}
                            >
                              <InputLabel htmlFor="constitucion-select">
                                Años constitución de la compañia
                              </InputLabel>
                              <Select
                                value={value}
                                onChange={onChange}
                                inputProps={{
                                  name: "constitucion",
                                  id: "constitucion-select",
                                }}
                              >
                                {dataConstitutionTime &&
                                  dataConstitutionTime.map(
                                    (item: any, index: number) => (
                                      <MenuItem
                                        value={item.id}
                                        key={index}
                                        sx={{
                                          color: dataUserSearchedShow
                                            ? "black"
                                            : "gray",
                                        }}
                                      >
                                        {item.description}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    )}
                    {!watchPersona.includes("dica") && (
                      <Grid item xs={3}>
                        <Controller
                          name={"lastNameClient"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              size="small"
                              label="Apellido del cliente"
                              variant="standard"
                              value={value}
                              autoComplete="name3"
                              sx={{ "& input": { pl: "0!important" } }}
                              {...register("lastNameClient")}
                              error={
                                errors.lastNameClient &&
                                Boolean(errors.lastNameClient)
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={3}>
                      <Controller
                        name={"birthDate"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label={
                              watchPersona.includes("dica")
                                ? "Fecha de constitución"
                                : "Año de nacimiento"
                            }
                            type="number"
                            variant="standard"
                            value={value}
                            required
                            autoComplete="name3"
                            InputProps={{
                              inputProps: {
                                max: 2023,
                                min: 1900,
                              },
                            }}
                            sx={{ "& input": { pl: "0!important" } }}
                            {...register("birthDate")}
                            error={
                              errors.birthDate && Boolean(errors.birthDate)
                            }
                            helperText={
                              errors.birthDate &&
                              errors.birthDate.type === "maxLength"
                                ? "El año de nacimiento debe tener máximo 4 dígitos"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography color="red" variant="subtitle1">
                      Seleccione un tipo de persona
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded && watchPersona !== undefined}
            sx={{
              background: "#fafafa",
              width: "100%",
              boxShadow:
                "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
              borderRadius: "15px !important",
              marginTop: "20px",
              borderTop: 0,
              "& .MuiAccordionDetails-root": {
                padding: 5,
              },
            }}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              sx={{
                borderBottom: "1px solid #8BC8BC",
                borderRadius: 0,
                width: "98%",
                marginX: "auto",
              }}
            >
              <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
                Detalles
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                // height: 225,
                width: "100%",
                gap: "20px",
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={valueTab}
                onChange={handleChangeTab}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  width: "15%",
                  minWidth: "150px",
                }}
              >
                {listShow?.map(
                  (item: any, index: number) =>
                    searchFactor(item.label).length > 0 && (
                      <Tab
                        key={index}
                        label={item.label}
                        {...a11yProps(index)}
                        sx={{
                          color: "#A2A2A2",
                          textAlign: "left",
                          borderRadius: 0,
                          borderBottom: "2px solid #8BC8BC",
                          width: "100%",
                          alignItems: "flex-start",
                        }}
                      />
                    )
                )}
              </Tabs>
              {listShow &&
                listShow.map((item: any, index: any) => (
                  <TabPanel value={valueTab} index={index} key={index}>
                    {item.label !== "Datos PEPS" && (
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                width={100}
                                align="center"
                                sx={{
                                  color: "#44A492 !important",
                                  fontWeight: "bold",
                                }}
                              >
                                Peso %
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#44A492 !important",
                                  fontWeight: "bold",
                                }}
                                align="center"
                                width={280}
                              ></TableCell>
                              <TableCell
                                sx={{
                                  color: "#44A492 !important",
                                  fontWeight: "bold",
                                }}
                                width={200}
                                align="center"
                              >
                                Nivel de Riesgo
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#44A492 !important",
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                Ponderación Seleccion
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#44A492 !important",
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                Contribución Global
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#44A492 !important",
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                Alto riesgo
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {searchFactor(item.label).length > 0 &&
                              searchFactor(item.label).map(
                                (factor: any, i: number) =>
                                  factor.name === "Provincia" &&
                                  isRD === null ? null : (
                                    <TableRow
                                      key={i}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell align="center">
                                        <Typography variant="subtitle1">
                                          {factor.percentage}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Controller
                                          control={control}
                                          name={factor.name}
                                          rules={{ required: true }}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <Autocomplete
                                              fullWidth
                                              disabled={
                                                factor.name ===
                                                "Tipo de persona"
                                              }
                                              onChange={(
                                                event,
                                                newValue: any
                                              ) => {
                                                onChange(newValue);
                                                onChangeOptionsSelected(
                                                  newValue,
                                                  factor
                                                );
                                              }}
                                              options={
                                                isRD &&
                                                factor.name === "Provincia"
                                                  ? factor.options.filter(
                                                      (option: any) =>
                                                        option.description !==
                                                        "Reside en el exterior"
                                                    )
                                                  : !isRD &&
                                                    factor.name === "Provincia"
                                                  ? factor.options.filter(
                                                      (option: any) =>
                                                        option.description ===
                                                        "Reside en el exterior"
                                                    )
                                                  : factor.options !== null
                                                  ? factor.options
                                                  : []
                                              }
                                              getOptionLabel={(option: any) =>
                                                option.description || ""
                                              }
                                              isOptionEqualToValue={(
                                                option: any,
                                                value: any
                                              ) =>
                                                optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.name
                                                )
                                                  ? optionsSelected.find(
                                                      (option: any) =>
                                                        option.factor ===
                                                        factor.name
                                                    ).optionsSelected.id ===
                                                    value.id
                                                  : false
                                              }
                                              // @ts-ignore
                                              value={
                                                optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.name
                                                )
                                                  ? optionsSelected.find(
                                                      (option: any) =>
                                                        option.factor ===
                                                        factor.name
                                                    ).optionsSelected
                                                  : null
                                              }
                                              sx={{
                                                "& .MuiInputLabel-shrink": {
                                                  transform:
                                                    "translate(0, 6px) scale(0.7)",
                                                },
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  fullWidth
                                                  disabled={
                                                    factor.name ===
                                                    "Tipo de persona"
                                                  }
                                                  label={factor.name}
                                                  variant="standard"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                        {optionsSelected.length > 0 &&
                                          optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          ) &&
                                          !!optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          ).optionsSelected.subElements &&
                                          optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          ).optionsSelected.subElements.length >
                                            0 && (
                                            <Controller
                                              control={control}
                                              name={
                                                optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.name
                                                ).optionsSelected.description
                                              }
                                              rules={{ required: true }}
                                              render={({
                                                field: { onChange, value },
                                              }) => (
                                                <FormControl
                                                  variant="standard"
                                                  fullWidth
                                                >
                                                  <Select
                                                    fullWidth
                                                    label=""
                                                    defaultValue={""}
                                                    value={
                                                      optionsSelected.find(
                                                        (option: any) =>
                                                          option.factor ===
                                                          optionsSelected.find(
                                                            (option: any) =>
                                                              option.factor ===
                                                              factor.name
                                                          ).optionsSelected
                                                            .description
                                                      )
                                                        ? optionsSelected.find(
                                                            (option: any) =>
                                                              option.factor ===
                                                              optionsSelected.find(
                                                                (option: any) =>
                                                                  option.factor ===
                                                                  factor.name
                                                              ).optionsSelected
                                                                .description
                                                          ).optionsSelected.name
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      onChange(e);
                                                      onChangeSubElementSelected(
                                                        e.target.value,
                                                        optionsSelected.find(
                                                          (option: any) =>
                                                            option.factor ===
                                                            factor.name
                                                        ).optionsSelected
                                                      );
                                                    }}
                                                    sx={{
                                                      ".MuiSelect-select": {
                                                        textAlign: "left",
                                                      },
                                                    }}
                                                  >
                                                    {optionsSelected
                                                      .find(
                                                        (option: any) =>
                                                          option.factor ===
                                                          factor.name
                                                      )
                                                      .optionsSelected.subElements.map(
                                                        (
                                                          item: any,
                                                          index: number
                                                        ) => (
                                                          <MenuItem
                                                            key={index}
                                                            value={item.name}
                                                          >
                                                            {item.name}
                                                          </MenuItem>
                                                        )
                                                      )}
                                                  </Select>
                                                </FormControl>
                                              )}
                                            />
                                          )}
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography variant="subtitle1">
                                          {!!optionsSelected.length &&
                                          optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          )
                                            ? `${
                                                optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.name
                                                ).optionsSelected.risk
                                              } - `
                                            : ""}

                                          {optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          ) &&
                                          optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          ).optionsSelected.risk === 0 ? (
                                            "Muy bajo"
                                          ) : (
                                            <FindDescription
                                              riskValue={
                                                optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.name
                                                )
                                                  ? optionsSelected.find(
                                                      (option: any) =>
                                                        option.factor ===
                                                        factor.name
                                                    ).optionsSelected.risk
                                                  : "N/D"
                                              }
                                            />
                                          )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography variant="subtitle1">
                                          {optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          )
                                            ? calculatePonderation(
                                                optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.name
                                                ).optionsSelected.risk
                                              )
                                            : "N/D"}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography variant="subtitle1">
                                          {optionsSelected.find(
                                            (option: any) =>
                                              option.factor === factor.name
                                          )
                                            ? (
                                                calculatePonderation(
                                                  optionsSelected.find(
                                                    (option: any) =>
                                                      option.factor ===
                                                      factor.name
                                                  ).optionsSelected.risk
                                                ) *
                                                (factor.percentage / 100)
                                              ).toFixed(2)
                                            : "N/D"}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Checkbox
                                          checked={factor.high_risk}
                                          disabled
                                          sx={{
                                            color: "#8BC8BC",
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                    {item.label === "Datos PEPS" && (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          marginLeft="auto"
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="contained"
                            onClick={onChangeOptionsPEPSToNo}
                          >
                            Marcar NO a todos
                          </Button>
                        </Box>

                        <Grid container spacing={6}>
                          <Grid
                            item
                            xs={7}
                            display="flex"
                            flexDirection="column"
                            gap={4}
                          >
                            {!!searchFactor("Datos PEPS")[0] &&
                              searchFactor("Datos PEPS")[0]
                                .options.filter(
                                  (option: any) =>
                                    option.description !== "Especifique" &&
                                    option.description !== "Tipo PEP" &&
                                    option.description !== "Vinculación"
                                )
                                .map((factor: any, i: number) => (
                                  <Controller
                                    key={i}
                                    control={control}
                                    name={`pep.${factor.id}`}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <FormControl variant="standard" fullWidth>
                                        <Typography
                                          variant="subtitle1"
                                          fontSize="14px"
                                        >
                                          {factor.description}
                                        </Typography>
                                        <Select
                                          fullWidth
                                          label=""
                                          value={
                                            optionsSelected.find(
                                              (option: any) =>
                                                option.factor ===
                                                factor.description
                                            )
                                              ? optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.description
                                                ).optionsSelected.name
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChange(e);
                                            onChangeOptionsPEPS(
                                              e.target.value,
                                              factor
                                            );
                                          }}
                                        >
                                          {factor.subElements &&
                                            factor.subElements.map(
                                              (item: any, index: number) => (
                                                <MenuItem
                                                  key={index}
                                                  value={item.name}
                                                >
                                                  {item.name}
                                                </MenuItem>
                                              )
                                            )}
                                        </Select>
                                      </FormControl>
                                    )}
                                  />
                                ))}
                            {!!searchFactor("Datos PEPS")[0] &&
                              isPep.some(
                                (item: any) =>
                                  item.name.includes("anteriores") &&
                                  item.value === "SI"
                              ) &&
                              searchFactor("Datos PEPS")[0]
                                .options.filter(
                                  (option: any) =>
                                    option.description === "Vinculación"
                                )
                                .map((factor: any, i: number) => (
                                  <Controller
                                    key={i}
                                    control={control}
                                    name={`pep.${factor.id}`}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <FormControl variant="standard" fullWidth>
                                        <Typography
                                          variant="subtitle1"
                                          fontSize="14px"
                                        >
                                          {factor.description}
                                        </Typography>
                                        <Select
                                          fullWidth
                                          label=""
                                          value={
                                            optionsSelected.find(
                                              (option: any) =>
                                                option.factor ===
                                                factor.description
                                            )
                                              ? optionsSelected.find(
                                                  (option: any) =>
                                                    option.factor ===
                                                    factor.description
                                                ).optionsSelected.name
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChange(e);
                                            onChangeOptionsPEPS(
                                              e.target.value,
                                              factor
                                            );
                                          }}
                                          inputProps={{
                                            shrink: true,
                                          }}
                                        >
                                          {factor.subElements &&
                                            factor.subElements.map(
                                              (item: any, index: number) => (
                                                <MenuItem
                                                  key={index}
                                                  value={item.name}
                                                >
                                                  {item.name}
                                                </MenuItem>
                                              )
                                            )}
                                        </Select>
                                      </FormControl>
                                    )}
                                  />
                                ))}
                          </Grid>

                          <Grid
                            item
                            xs={5}
                            display="flex"
                            flexDirection="column"
                            gap={4}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={2}
                              justifyContent="space-between"
                            >
                              <Typography variant="subtitle1">
                                Cliente tipo PEP:{" "}
                                <b>
                                  {isPep.some(
                                    (item: any) => item.value === "SI"
                                  )
                                    ? "Si"
                                    : "No"}
                                </b>
                              </Typography>
                              {isPep.some(
                                (item: any) => item.value === "SI"
                              ) && (
                                <Typography variant="subtitle1">
                                  Tipo PEP:{" "}
                                  <b>
                                    {isPep.find((item: any) =>
                                      item.name.includes("anteriores")
                                    )?.value === "SI"
                                      ? "Vínculo familiar"
                                      : "Directo"}
                                  </b>
                                </Typography>
                              )}
                            </Box>

                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={2}
                              justifyContent="space-between"
                            >
                              <Typography variant="subtitle1">
                                Especifique
                              </Typography>
                              <Controller
                                control={control}
                                name="comments"
                                render={({ field: { onChange, value } }) => (
                                  <TextField
                                    value={value}
                                    multiline
                                    maxRows={4}
                                    {...register("comments")}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        pl: 0,
                                      },
                                    }}
                                  />
                                )}
                              />
                              {/* <textarea
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        resize: "none",
                                        border: "1px solid #8BC8BC",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        marginTop: "10px",
                                      }}
                                    /> */}
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </TabPanel>
                ))}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expendedTab2 && watchPersona !== undefined}
            elevation={4}
            sx={{
              background: "#fafafa",
              marginTop: "20px",
              width: "100%",
              boxShadow:
                "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
              borderRadius: "15px !important",
              "& .MuiAccordionDetails-root": {
                padding: 5,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              onClick={() => setExpendedTab2(!expendedTab2)}
              sx={{
                borderBottom: "1px solid #8BC8BC",
                borderRadius: 0,
                width: "100%",
                padding: "0 20px",
              }}
              id="panel1bh-header"
            >
              <Typography variant="h5" sx={{ width: "70%", flexShrink: 0 }}>
                Resultados
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Resumen de resultados
                  </Typography>
                </Grid>
                <Grid item xs={4} gap={10}>
                  <Typography marginBottom="1rem" variant="subtitle1">
                    Peso Total % :{" "}
                    <b>
                      {optionsSelected.length > 0
                        ? optionsSelected.reduce(
                            (acc: any, curr: any) => acc + curr.percentage,
                            0
                          )
                        : 0}
                    </b>
                  </Typography>
                  <Typography variant="subtitle1" marginBottom="1rem">
                    Total Contribución Global :{" "}
                    <b>
                      {optionsSelected.length > 0
                        ? optionsSelected
                            .reduce(
                              (acc: any, curr: any) => acc + curr.contribution,
                              0
                            )
                            .toFixed(2)
                        : 0}
                    </b>
                  </Typography>
                  <Typography variant="subtitle1" marginBottom="1rem">
                    Total Factores Alto Riesgo :{" "}
                    <b>
                      {optionsSelected.length > 0
                        ? optionsSelected.filter(
                            (item: any) => item.highRisk === 1
                          ).length +
                          (isPep.some((item: any) => item.value === "SI")
                            ? 1
                            : 0)
                        : 0}
                    </b>
                  </Typography>
                  <Typography variant="subtitle1" marginBottom="1rem">
                    Cálculo de Riesgo :{" "}
                    <b>
                      {getCaptionByNumber(
                        optionsSelected.length > 0
                          ? optionsSelected
                              .reduce(
                                (acc: any, curr: any) =>
                                  acc + curr.contribution,
                                0
                              )
                              .toFixed(2)
                          : 0
                      )}
                    </b>
                  </Typography>
                  {/* </Grid> */}
                </Grid>
                <Grid item xs={8}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle1">
                      Factores Alto Riesgo Detectados
                    </Typography>
                    <Box
                      width="100%"
                      sx={{
                        border: "1px solid #8BC8BC",
                        borderRadius: "5px",
                        padding: "5px",
                        marginTop: "10px",
                        height: "120px",
                      }}
                    >
                      <Typography>
                        {isPep.some((item: any) => item.value === "SI") &&
                          `Es una persona políticamente expuesta (${
                            isPep.find((item: any) =>
                              item.name.includes("anteriores")
                            )?.value === "SI"
                              ? "Vínculo familiar"
                              : "Directo"
                          }) - `}
                        {optionsSelected.length > 0
                          ? optionsSelected
                              .filter((item: any) => item.highRisk === 1)
                              .map(
                                (item: any, index: number) =>
                                  `${item.factor} - ${
                                    item.optionsSelected.description ??
                                    item.optionsSelected.name
                                  } ${
                                    optionsSelected.length - 1 !== index
                                      ? ","
                                      : ""
                                  } `
                              )
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            marginTop="2rem"
          >
            <Button variant="contained" onClick={onSave}>
              <Typography>Guardar</Typography>
            </Button>
          </Box>
        </Grid>
      )}
      <Dialog
        open={showInitModal}
        onClose={handleCloseInitModal}
        fullWidth
        disableEscapeKeyDown={false}
      >
        <Title
          id="alert-dialog-title"
          sx={{
            pt: 4,
            px: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h5">Matriz de Riesgo</Typography>
        </Title>
        <DialogContent>
          <Text id="alert-dialog-description">
            {!dataUserSearchedShow ? (
              <form onSubmit={handleSubmit(handleSearchUser)}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginY="1rem"
                >
                  <RadioGroupInput
                    name="personType"
                    control={control}
                    errors={errors}
                    label="Tipo de persona"
                    defaultValue={"fisica"}
                    data={[
                      { value: "juridica", label: "Jurídica" },
                      { value: "fisica", label: "Física" },
                    ]}
                  />
                  <Typography color="black">
                    Buscar por número de identificación:
                  </Typography>
                  {watcCustomPersonType === "juridica" ? (
                    <Controller
                      control={control}
                      name="searchUser"
                      rules={{
                        required: "Este campo es obligatorio",
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Formato no válido",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          onChange={onChange}
                          value={value}
                          required
                          fullWidth
                          sx={{
                            maxWidth: "300px",
                          }}
                          inputProps={{}}
                          variant="standard"
                          error={!!errors.searchUser}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <Controller
                        name={"searchUser"}
                        control={control}
                        rules={{
                          required: "Este campo es obligatorio",
                        }}
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            sx={{
                              width: "100%",
                              maxWidth: "300px",
                              color: "#5d5e60",
                            }}
                            className={`input-mapprisk ${
                              errors.document_id_number && "error"
                            }`}
                          >
                            <PatternFormat
                              name={"idClient"}
                              onPaste={(e: any) => e.preventDefault()}
                              mask="_"
                              format={"###-#######-#"}
                              value={value}
                              onChange={onChange}
                              placeholder={"###-#######-#"}
                            />
                            <span></span>
                          </FormControl>
                        )}
                      />
                      {errors.searchUser && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                        >
                          {/* @ts-ignore */}
                          {errors.searchUser.message ?? ""}
                        </span>
                      )}
                    </>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      marginTop: "2rem",
                      width: "150px",
                      height: "30px",
                    }}
                  >
                    Buscar
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowInitModal(false)}
                    sx={{
                      marginTop: "1rem",
                      height: "30px",
                    }}
                  >
                    Crear nueva matriz
                  </Button>
                </Box>
              </form>
            ) : (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  marginY="1rem"
                  textAlign="left"
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Cédula/RNC:</strong>
                          </TableCell>
                          <TableCell>
                            {dataUserSearchedShow.document_number}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Nombre cliente:</strong>
                          </TableCell>
                          <TableCell>{dataUserSearchedShow.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Fecha de actualización:</strong>
                          </TableCell>
                          <TableCell>
                            {dataUserSearchedShow.updated_at || "N/D"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Nivel de riesgo:</strong>
                          </TableCell>
                          <TableCell>
                            {dataUserSearchedShow.risk_level || "N/D"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: "2rem",
                      width: "150px",
                      marginX: "auto",
                    }}
                    onClick={uploadDataInForm}
                  >
                    Cargar datos
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowInitModal(false)}
                    sx={{
                      marginTop: "1rem",
                      width: "200px",
                      height: "30px",
                      marginX: "auto",
                    }}
                  >
                    Crear nueva matriz
                  </Button>
                </Box>
              </>
            )}
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setDataUserSearchedShow(null)}
          >
            Regresar
          </Button>
          <Button variant="outlined" onClick={handleCloseInitModal}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Step1Agrupacion;
